.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centeredOverlay ._loading_overlay_spinner {
  position: fixed !important;
  top: calc(100vh / 2) !important;
  left: calc(100vw / 2 - 25px);
}

.centeredOverlay ._loading_overlay_content {
  position: fixed;
  top: calc(100vh / 2 + 80px);
  width: 100%;
  z-index: 2500 !important;
}

.NftThreeCard {
  z-index: 260 !important;
}

.NotCenteredOverlay ._loading_overlay_spinner {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  
}

.NotCenteredOverlay ._loading_overlay_spinner svg{
  margin-bottom: 10px;
  width: 50px;
}

.NotCenteredOverlay ._loading_overlay_content {
  position: unset !important;
  top: unset !important;
  width: unset !important;
}

.navigation {
  position: relative;
  display: inline-block;
}

.navigation-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-top: 12px;
}

.navigation a {
  color: black;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 2px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation:hover .navigation-content {
  display: block;
}

.landSubmenu .btn{
  padding-bottom: 12px !important;
  color: #20005e !important;
}

.newSubCategory {
  margin-top: 15px;
}